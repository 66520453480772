import React from 'react'
import { baseURL } from '../constants/BaseUrl'
function sectionB() {
    return (
        <div className='mx-4 md:mt-[100px] mt-[60px] bg-[#FAFAFA] rounded-3xl py-12'>
            <div className='section-wraper'>
                <h2 className='w-[90%] mx-auto'>The ultimate solution to your customer communication needs!</h2>
                <div className="grid grid-flow-row lg:grid-cols-3 md:grid-cols-2 gap-5 md:mt-20 mt-8">

                    <div className="sec2-card  flex flex-col text-left">
                        <img className='w-10 h-10' src={`${baseURL}images/card6.png`} alt="card6" />
                        <div className='card-head my-2'>Guest Enquiry</div>
                        <p>Gives you hold of every single enquiry made by the guests through multiple platforms consistently</p>
                        <div className='see-more-btn flex space-x-2 items-center mt-4 cursor-pointer'>
                            <div className='see'>See more</div>
                            <svg className='see-more-icon' width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.77734C0.723858 3.77734 0.5 4.0012 0.5 4.27734C0.5 4.55349 0.723858 4.77734 1 4.77734V3.77734ZM11.3536 4.6309C11.5488 4.43564 11.5488 4.11905 11.3536 3.92379L8.17157 0.74181C7.97631 0.546548 7.65973 0.546548 7.46447 0.74181C7.2692 0.937072 7.2692 1.25365 7.46447 1.44892L10.2929 4.27734L7.46447 7.10577C7.2692 7.30103 7.2692 7.61762 7.46447 7.81288C7.65973 8.00814 7.97631 8.00814 8.17157 7.81288L11.3536 4.6309ZM1 4.77734H11V3.77734H1V4.77734Z" fill="#4D30A6" />
                            </svg>
                        </div>
                    </div>
                    <div className="sec2-card flex flex-col text-left">
                        <img className='w-10 h-10' src={`${baseURL}images/card5.png`} alt="card1" />
                        <div className='card-head my-2'>Supervisor panel</div>
                        <p>The supervisor panel is an essential feature for any businesses or organizations that require strict control and management of their online operations</p>
                        <div className='see-more-btn flex space-x-2 items-center mt-4 cursor-pointer'>
                            <div className='see'>See more</div>
                            <svg className='see-more-icon' width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.77734C0.723858 3.77734 0.5 4.0012 0.5 4.27734C0.5 4.55349 0.723858 4.77734 1 4.77734V3.77734ZM11.3536 4.6309C11.5488 4.43564 11.5488 4.11905 11.3536 3.92379L8.17157 0.74181C7.97631 0.546548 7.65973 0.546548 7.46447 0.74181C7.2692 0.937072 7.2692 1.25365 7.46447 1.44892L10.2929 4.27734L7.46447 7.10577C7.2692 7.30103 7.2692 7.61762 7.46447 7.81288C7.65973 8.00814 7.97631 8.00814 8.17157 7.81288L11.3536 4.6309ZM1 4.77734H11V3.77734H1V4.77734Z" fill="#4D30A6" />
                            </svg>
                        </div>
                    </div>
                    <div className="sec2-card flex flex-col text-left">
                        <img className='w-10 h-10' src={`${baseURL}images/card1.png`} alt="card5" />
                        <div className='card-head my-2'>Booking</div>
                        <p>Your guests will be able to make bookings by interacting with the Edaya Chatbot without redirecting to a booking interface</p>
                        <div className='see-more-btn flex space-x-2 items-center mt-4 cursor-pointer'>
                            <div className='see'>See more</div>
                            <svg className='see-more-icon' width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.77734C0.723858 3.77734 0.5 4.0012 0.5 4.27734C0.5 4.55349 0.723858 4.77734 1 4.77734V3.77734ZM11.3536 4.6309C11.5488 4.43564 11.5488 4.11905 11.3536 3.92379L8.17157 0.74181C7.97631 0.546548 7.65973 0.546548 7.46447 0.74181C7.2692 0.937072 7.2692 1.25365 7.46447 1.44892L10.2929 4.27734L7.46447 7.10577C7.2692 7.30103 7.2692 7.61762 7.46447 7.81288C7.65973 8.00814 7.97631 8.00814 8.17157 7.81288L11.3536 4.6309ZM1 4.77734H11V3.77734H1V4.77734Z" fill="#4D30A6" />
                            </svg>
                        </div>

                    </div>
                    <div className="sec2-card flex flex-col text-left">
                        <img className='w-10 h-10' src={`${baseURL}images/card7.png`} alt="card2" />
                        <div className='card-head my-2'>Case management system</div>
                        <p>Hospitality-specific case management system to simplify and consolidate guest interactions</p>
                        <div className='see-more-btn flex space-x-2 items-center mt-4 cursor-pointer'>
                            <div className='see'>See more</div>
                            <svg className='see-more-icon' width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.77734C0.723858 3.77734 0.5 4.0012 0.5 4.27734C0.5 4.55349 0.723858 4.77734 1 4.77734V3.77734ZM11.3536 4.6309C11.5488 4.43564 11.5488 4.11905 11.3536 3.92379L8.17157 0.74181C7.97631 0.546548 7.65973 0.546548 7.46447 0.74181C7.2692 0.937072 7.2692 1.25365 7.46447 1.44892L10.2929 4.27734L7.46447 7.10577C7.2692 7.30103 7.2692 7.61762 7.46447 7.81288C7.65973 8.00814 7.97631 8.00814 8.17157 7.81288L11.3536 4.6309ZM1 4.77734H11V3.77734H1V4.77734Z" fill="#4D30A6" />
                            </svg>
                        </div>
                    </div>
                    <div className="sec2-card flex flex-col text-left">
                        <img className='w-10 h-10' src={`${baseURL}images/card8.png`} alt="card3" />
                        <div className='card-head my-2'>Social media connector</div>
                        <p>Social media connectors allow users to manage multiple social media accounts from one platform</p>
                        <div className='see-more-btn flex space-x-2 items-center mt-4 cursor-pointer'>
                            <div className='see'>See more</div>
                            <svg className='see-more-icon' width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.77734C0.723858 3.77734 0.5 4.0012 0.5 4.27734C0.5 4.55349 0.723858 4.77734 1 4.77734V3.77734ZM11.3536 4.6309C11.5488 4.43564 11.5488 4.11905 11.3536 3.92379L8.17157 0.74181C7.97631 0.546548 7.65973 0.546548 7.46447 0.74181C7.2692 0.937072 7.2692 1.25365 7.46447 1.44892L10.2929 4.27734L7.46447 7.10577C7.2692 7.30103 7.2692 7.61762 7.46447 7.81288C7.65973 8.00814 7.97631 8.00814 8.17157 7.81288L11.3536 4.6309ZM1 4.77734H11V3.77734H1V4.77734Z" fill="#4D30A6" />
                            </svg>
                        </div>
                    </div>
                    
                    <div className="sec2-card flex flex-col text-left">
                        <img className='w-10 h-10' src={`${baseURL}images/card9.png`} alt="card4" />
                        <div className='card-head my-2'>Smart-x rule engine</div>
                        <p>The Smart-X Rule Engine streamlines decision-making processes by using machine learning and AI to analyze data and make intelligent decisions in real-time</p>
                        <div className='see-more-btn flex space-x-2 items-center mt-4 cursor-pointer'>
                            <div className='see'>See more</div>
                            <svg className='see-more-icon' width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.77734C0.723858 3.77734 0.5 4.0012 0.5 4.27734C0.5 4.55349 0.723858 4.77734 1 4.77734V3.77734ZM11.3536 4.6309C11.5488 4.43564 11.5488 4.11905 11.3536 3.92379L8.17157 0.74181C7.97631 0.546548 7.65973 0.546548 7.46447 0.74181C7.2692 0.937072 7.2692 1.25365 7.46447 1.44892L10.2929 4.27734L7.46447 7.10577C7.2692 7.30103 7.2692 7.61762 7.46447 7.81288C7.65973 8.00814 7.97631 8.00814 8.17157 7.81288L11.3536 4.6309ZM1 4.77734H11V3.77734H1V4.77734Z" fill="#4D30A6" />
                            </svg>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default sectionB