import React from 'react'
import { Link } from 'react-router-dom'
import { baseURL } from '../constants/BaseUrl'
const imageUrl = "images/future.png";
const backgroundImageUrl = `${baseURL}${imageUrl}`;
function SectionE() {
    return (
      <div className='build mt-0 md:mt-[120px] flex flex-col justify-center items-center p-4 bg-none sm:bg-cover' style={{backgroundImage:`url(${backgroundImageUrl})`}}>
        <div className='bg-white p-3'>
        <div className='words font-semibold text-[24px] md:text-[36px] lg:text-[40px] leading-[38px] md:leading-[50px] lg:leading-[54px] text-center'>Reimagining customer <br></br> communication for the future</div>
        <div className='flex justify-center items-center mt-[32px]'>
          <Link to="/contact-us"  className='font-semibold text-base text-white bg-[#4D30A6] hover:bg-[#6e45e6] lg:px-5 lg:py-4 px-4 py-[14px] rounded-md'>Get started</Link>
        </div>
        </div>
      </div>
    )
}

export default SectionE