import React from 'react'
import { Link } from 'react-router-dom'

function Chat1() {
    return (
        <div>
            <div className='mt-[56px] lg:py-[120px] py-20' style={{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0.01%, #C0C4FF 100%)' }}>
                <div className="flex flex-col justify-center items-center">
                    <h2>Join The Edaya Chatbot<br></br> <span className='mt-3'>Revolution Today</span>​</h2>
                    <Link to='/contact-us' className='mt-6 nav-sign-in'>Start Chatting​</Link>
                </div>
            </div>
        </div>
    )
}

export default Chat1
