import React from 'react'
import { Link } from 'react-router-dom';
import { useRef, useState, useEffect } from "react";
import  {baseURL}  from '../constants/BaseUrl';
import TextTransition, { presets } from 'react-text-transition';
import ScrollTrigger from 'react-scroll-trigger';

const TEXTS = ['All-in engagement platform', 'AI-powered chatbot', 'Intelligent analytics'];

function SectionA() {

    const [index, setIndex] = React.useState(0);
    const [IsZoom, setZoom] = useState(true)
    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            2200,
        );
        return () => clearTimeout(intervalId);
    }, []);






    return (
        <div className='relative md:mt-[140px] mt-[100px]'>
            <div className="absolute md:top-[-80px] top-[-40px] md:h-20 h-10 w-full bg-white z-10"></div>
            <div className="section-wrapper">
                <h1 className='w-full mx-auto scroll-part'>
                    <ScrollTrigger onEnter={() => setZoom(true)} onExit={() => setZoom(false)} className='text-[#4D30A6] inline-block'>
                        <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition>
                    </ScrollTrigger>
                    <br />
                    <div className='relative sm:bottom-[3px] bottom-[10px]'>for superior guest interaction</div></h1>
                     <p className='md:w-[750px] sm:w-5/6 w-full  mx-auto section-para md:mt-4 mt-0 sm:px-0 px-2'>Edaya Digital Suite helps your organization effectively handle visitor queries from your website, social media platforms and live chat, ensuring better connection and interaction with them</p>
                 <div  className="mt-7">
                    <Link to='/contact-us' className='sec-btn block w-fit mx-auto z-10 bg-[#4D30A6] text-white'>Book free demo</Link>
                </div>
            </div>
            <div className='lg:mt-20 sm:mt-10 mt-6 w-full px-4'>
                <div className="sectionA-banner  justify-center items-center">
                    <div className="section-wrapper relative hidden lg:flex justify-center">
                        <div className="relative">
                            <img className='w-[600px] h-[446px]' src={`${baseURL}images/digitalDashboard.png`} alt="" />
                            <div className={IsZoom ? 'zoomedOut container' : 'zoomed container'}>
                                <img src={`${baseURL}images/twitter.png`} alt="Image" />
                            </div>
                            <div className={IsZoom ? 'zoomedOut container1' : 'zoomed container1'}>
                                <img src={`${baseURL}images/instagram.png`} alt="Image" />
                            </div>
                            <div className={IsZoom ? 'zoomedOut container2' : 'zoomed container2'}>
                                <img src={`${baseURL}images/Facebooks.png`} alt="Image" />
                            </div>
                            <div className={IsZoom ? 'zoomedOut container3' : 'zoomed container3'}>
                                <img src={`${baseURL}images/Chatbot1.png`} alt="Image" />
                            </div>
                        </div>
                    </div>
                    <div className="flex lg:hidden">
                        <img src={`${baseURL}images/dashboard-resp.png`} alt="" />
                    </div>
                </div>
            </div>

        </div >
    )
}

export default SectionA