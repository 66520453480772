import React, { useState } from 'react';

import { Link, NavLink } from "react-router-dom";

function Header() {

  const [navOpen, setNavOpen] = useState(false);

  function toggleNav() {
    setNavOpen(!navOpen);
  }

  const toTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div className='fixed top-0 z-50 w-full header-bg shadow3'>
      <div className='header-section section-wraper'>
        <NavLink to="/" onClick={toTop} className="digital-suit-logo">Edaya CX</NavLink>
        <ul className='flex space-x-10 header-nav'>
          <li>
            <NavLink to="/" activeclassname="active">Home</NavLink>
          </li>
          <li>
            <a target='_blank' href="https://edayaapp.com/about-us">About Edaya </a>
          </li>
          <li>
          <NavLink to="/chatbot" activeclassname="active">Chatbot</NavLink>
          </li>
          <li >
            <NavLink to="/contact-us" activeclassname="active">Contact us</NavLink>
          </li>

          <li>
            <NavLink to="/sign-up" className='nav-sign-in' href="">Sign in</NavLink>
          </li>
        </ul>
        <button onClick={toggleNav} className="cursor-pointer responsive-btn">
          {navOpen ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
          ) : (
            <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 1H17" stroke="black" strokeWidth="2" />
              <path d="M1 6H16" stroke="black" strokeWidth="2" />
              <path d="M3.5 11H13.5" stroke="black" strokeWidth="2" />
            </svg>

          )}
        </button>
      </div>

      <nav className={navOpen ? "resp-nav flex" : "resp-nav hidden"}>
        <ul className='w-full text-left px-5 section-wraper pb-4'>

          <li>
            <NavLink to="/" activeclassname="active">Home</NavLink>
          </li>
          <li>
            <a target='_blank' href="https://edayaapp.com/about-us" style={{ width: '100%' }}>About Edaya</a>
          </li>
          <li>
            <NavLink to="/chatbot" activeclassname="active">Chatbot</NavLink>
          </li>
          <li>
            <NavLink to="/contact-us" activeclassname="active">Contact us</NavLink>
          </li>
          <li>
            <NavLink to="/sign-up" className='nav-sign-in w-fit' activeclassname="active">Sign in</NavLink>
          </li>
        </ul>
      </nav>
    </div >
  )
}

export default Header