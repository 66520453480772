import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Footer from './Footer'
import Header from './Header'
import Contact from './Contact';
import { baseURL } from '../constants/BaseUrl';
import cover1 from '../pics/cover1.png';
import cover2 from '../pics/cover2.png';
import cover3 from '../pics/cover3.png';
import cover4 from '../pics/cover4.png';
import Chat1 from './Chat1';
import hospitality from '../pics/Hospitality.png'
import health from '../pics/Healthcare.png'
import ecomm from '../pics/ecommerce.png'
import gov from '../pics/Government.png'
import auto from '../pics/Automobile.png'
import edu from '../pics/Education.png'

function Chatbot() {
    const [currentImage, setCurrentImage] = useState(cover1);
    const [solutionImage, setSolutionImage] = useState(hospitality);

    const handleButtonClick = (newImage) => {
        setCurrentImage(newImage);
    };

    const solutionButtonClick = (newImage) => {
        setSolutionImage(newImage);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    

    return (
        <div>
            <Header />
            <div className='section-wraper mt-[120px]'>
                <div className="flex lg:flex-row flex-col justify-center items-center w-full">
                    <div className="flex-col lg:w-1/2 w-full">
                        <div className='first lg:text-left text-center'>Edaya Chatbot</div>
                        <h1 style={{ color: 'var(--color-black, #161616)' }} className='pt-2 text-center lg:text-left'><span className='lg:whitespace-nowrap'>Start Conversations</span> <span className='lg:whitespace-nowrap'>With Your Website</span> Visitors</h1>
                        <div className='firstP text-center lg:text-left pt-6'>Just like having a helpful friend around, the Edaya AI Chatbot guides visitors through your website and provides them with assistance.</div>
                        <Link to={{ pathname: '/contact-us', state: { comment: 'from chatbot' } }}>
                            <button className='mt-8 mx-auto lg:mx-0 nav-sign-in'>Get a Demo</button>
                        </Link>
                    </div>
                    <img className='lg:w-1/2 w-full mt-4 lg:mt-0' src={`${baseURL}images/Hero image.png`} alt="Image" />
                </div>
                <div className='section-wraper slide-track mt-[120px]'>
                    <div className='image-wrapper grid grid-flow-row lg:grid-cols-4 md:grid-cols-3 grid-cols-2 lg:gap-x-44 md:gap-x-56 sm:gap-x-60 gap-x-44 gap-y-14'>
                        <img className='' src={`${baseURL}images/winskart.png`} alt='img' />
                        <img className='' src={`${baseURL}images/imperium.png`} alt='img' />
                        <img className='' src={`${baseURL}images/inai.png`} alt='img' />
                        <img className='' src={`${baseURL}images/forte.png`} alt='img' />
                    </div>
                </div>

                <div style={{ borderRadius: '24px', background: '#F5F2FA', color: 'var(--color-primary, #4D30A6)' }} className='mt-[120px] p-8 lg:p-14'>
                    <h2 className='text-center'>What We Do ?</h2>
                    <p className='text-center font-medium pt-3 lg:w-[80%] mx-auto'>Transform your customer experience with AI powered virtual assistant. Think of it as your website's friendly helper- a chatbot that answers questions, offers advice, gives suggestions and makes things easier for you.</p>
                </div>
                <div className='lg:mt-[120px] mt-[56px]'>
                    <h2 className='text-center'><span style={{ color: 'var(--color-primary, #4D30A6)' }}>Edaya Chatbot</span> Got You Covered 24/7</h2>
                    <div className="hidden lg:flex lg:flex-row lg:space-x-6 mt-14">
                        <div className='cover w-1/2'>
                            <img src={currentImage} alt="Displayed" />
                        </div>
                        <div className='flex flex-col space-y-6 w-1/2'>
                            <button onClick={() => handleButtonClick(cover1)} className={`coverBox ${currentImage === cover1 ? 'active' : ''}`}>
                                <div className='coverH'>Instant Customer support</div>
                                <div className='coverP pt-2'>Get help right away with instant customer support – no more waiting!</div>
                            </button>
                            <button onClick={() => handleButtonClick(cover2)} className={`coverBox ${currentImage === cover2 ? 'active' : ''}`}>
                                <div className='coverH'>Interactive Marketing​</div>
                                <div className='coverP pt-2'>Reach Visitors with interactive marketing</div>
                            </button>
                            <button onClick={() => handleButtonClick(cover3)} className={`coverBox ${currentImage === cover3 ? 'active' : ''}`}>
                                <div className='coverH'>Guided Navigation​</div>
                                <div className='coverP pt-2'>​Your interactive guide for smooth website navigation</div>
                            </button>
                            <button onClick={() => handleButtonClick(cover4)} className={`coverBox ${currentImage === cover4 ? 'active' : ''}`}>
                                <div className='coverH'>Quick access to FAQs​</div>
                                <div className='coverP pt-2'>Skip the search – get quick answers through our chatbot​</div>
                            </button>
                        </div>
                    </div>
                    <div className='mt-14 lg:hidden flex flex-col space-y-12 w-full'>
                        <div className='flex flex-col justify-center items-center'>
                            <button>
                                <div className='coverH'>Instant Customer support</div>
                                <div className='coverP pt-2'>Get help right away with instant customer support – no more waiting!</div>
                            </button>
                            <div className='w-full sm:w-[500px] sm:h-[457.73px]'>
                                <img src={cover1} />
                            </div>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                            <button>
                                <div className='coverH'>Interactive Marketing​</div>
                                <div className='coverP pt-2'>Reach Visitors with interactive marketing</div>
                            </button>
                            <div className='w-full sm:w-[500px] sm:h-[457.73px] mt-4'>
                                <img src={cover2} />
                            </div>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                            <button>
                                <div className='coverH'>Guided Navigation​</div>
                                <div className='coverP pt-2'>​Your interactive guide for smooth website navigation</div>
                            </button>
                            <div className='w-full sm:w-[500px] sm:h-[457.73px] mt-4'>
                                <img src={cover3} />
                            </div>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                            <button>
                                <div className='coverH'>Quick access to FAQs​</div>
                                <div className='coverP pt-2'>Skip the search – get quick answers through our chatbot​</div>
                            </button>
                            <div className='w-full sm:w-[500px] sm:h-[457.73px] mt-4'>
                                <img src={cover4} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lg:mt-[120px] mt-[56px]'>
                    <h2 className='text-center'>How We Connect Better</h2>
                    <div className="my-16 flex lg:flex-row lg:space-x-6 flex-col w-full items-center">
                        <div className='flex flex-col w-full lg:w-[1/2] text-center lg:text-left'>
                            <h3>Discover a new level of interaction with Generative AI​</h3>
                            <p className='pt-4' style={{ color: 'var(--color-grey, #979797)' }}>Our solution employs generative AI chatbots to simplify your website, using interactive conversations and personalized assistance.</p>
                        </div>
                        <img className='lg:w-1/2 sm:w-[626px] sm:h-[336px] w-full lg:mt-0 mt-4' src={`${baseURL}images/connect1.png`} />
                    </div>
                    <div className="my-16 flex lg:flex-row lg:space-x-6 flex-col w-full items-center">
                        <div className='flex flex-col w-full lg:w-1/2 text-center lg:text-left'>
                            <h3>Automate customer Experience​​</h3>
                            <p className='pt-4' style={{ color: 'var(--color-grey, #979797)' }}>Use a chatbot to automatically greet clients, make product recommendations, and help them get started, resolving up to 70% of customer questions.</p>
                        </div>
                        <img className='lg:w-1/2 sm:w-[626px] sm:h-[336px] w-full lg:mt-0 mt-4' src={`${baseURL}images/connect2.png`} />
                    </div>
                    <div className="my-16 flex lg:flex-row lg:space-x-6 flex-col w-full items-center">
                        <div className='flex flex-col w-full lg:w-1/2 text-center lg:text-left'>
                            <h3>Escalate to expert support when needed​</h3>
                            <p className='pt-4' style={{ color: 'var(--color-grey, #979797)' }}>​When the chat gets complex or the visitor needs human assistance, the bot can easily transfer the chat to a human support.</p>
                        </div>
                        <img className='lg:w-1/2 sm:w-[626px] sm:h-[336px] w-full lg:mt-0 mt-4' src={`${baseURL}images/connect3.png`} />
                    </div>
                </div>
                <div className='lg:mt-[120px] mt-[56px] solution'>
                    <h2 style={{color: 'var(--color-black, #161616)', textAlign: 'center' }}>Chatbot Solutions for Diverse Verticals: Customized Excellence</h2>
                    <p style={{color: 'var(--color-black, #161616)', textAlign: 'center', paddingTop: '16px' }}>We help you with your customer service needs regardless of your industry.​</p>
                    <div className="hidden lg:flex space-x-12 mt-10 w-full">
                        <div className='flex flex-col space-y-4 pb-[100px] w-1/3'>
                            <button onClick={() => solutionButtonClick(hospitality)} className={`solutionTab ${solutionImage === hospitality ? 'active' : ''}`}>Hospitality</button>
                            <button onClick={() => solutionButtonClick(health)} className={`solutionTab ${solutionImage === health ? 'active' : ''}`}>Health care​</button>
                            <button onClick={() => solutionButtonClick(ecomm)} className={`solutionTab ${solutionImage === ecomm ? 'active' : ''}`}>E-Commerce​</button>
                            <button onClick={() => solutionButtonClick(gov)} className={`solutionTab ${solutionImage === gov ? 'active' : ''}`}>Government​</button>
                            <button onClick={() => solutionButtonClick(auto)} className={`solutionTab ${solutionImage === auto ? 'active' : ''}`}>Automobile​</button>
                            <button onClick={() => solutionButtonClick(edu)} className={`solutionTab ${solutionImage === edu ? 'active' : ''}`}>Education​</button>
                        </div>
                        <img className='solutionImg w-2/3' src={solutionImage} />
                    </div>
                    <div className='lg:hidden mt-10 flex flex-col space-y-4 pb-12 w-full'>
                        <button className='solutionTab'>Hospitality</button>
                        <button className='solutionTab'>Health care</button>
                        <button className='solutionTab'>E-Commerce</button>
                        <button className='solutionTab'>Government​</button>
                        <button className='solutionTab'>Automobile​</button>
                        <button className='solutionTab'>Education​</button>
                    </div>
                </div>
            </div>
            <Chat1 />
            <Footer />
        </div>
    )
}

export default Chatbot
