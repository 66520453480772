import logo from './logo.svg';
import './App.css';
import { Route, Routes, Link, Navigate, BrowserRouter } from "react-router-dom";
import Landing from './components/Landing';
import './css/style.css';
import './css/style1.css'
import Contact from './components/Contact';
import Signup from './components/Signup';
import Chatbot from './components/Chatbot';
import { HashRouter } from "react-router-dom";
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function App() {
  

  return (
    <div className="App">
      
      <HashRouter>
        <Routes basename="/">
          <Route path="/" element={<Landing />}></Route>
          <Route path="/contact-us" element={<Contact />}></Route>
          <Route path="/sign-up" element={<Signup />}></Route>
          <Route path="/chatbot" element={<Chatbot />}></Route>
        </Routes>
      </HashRouter>
 
    </div>
  );
}

export default App;
