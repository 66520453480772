import React from 'react'
import { baseURL } from '../constants/BaseUrl'
function SectionC() {
  return (
    <div className='section-wraper md:mt-[100px] mt-[60px]'>
      <h1 className='sm:w-[80%] md:text-[40px] mx-auto'>A comprehensive inbox designed for scalability, visibility, and collaboration</h1>
      <p className='w-[90%]  mx-auto section-para mt-4'>Our cross-channel chat platform offers a uniform experience across multiple channels, real-time chat, analytics and reporting tools to help organisations improve their communication strategy and deliver excellent customer experiences</p>
      <div className="mt-20 flex lg:flex-row flex-col justify-center mx-auto w-[94%]">
        <div className="lg:w-[55%] mx-auto">
          <img className='sm:w-[820px] sm:h-[330px] shrink-0' src= {`${baseURL}images/Dashboard.png`} alt="cardbg1" />
        </div>
        <div className='flex flex-col justify-center lg:mt-0 mt-6 lg:ml-14 lg:w-[45%]'>
          <h3 className='lg:text-left'>An Integrated Platform to Handle All Your Customer Enquiries</h3>
          <p className='section-para lg:text-left md:mt-6 mt-3 w-11/12'>Using an integrated omnichannel digital platform, you can streamline every interaction with clients. Maintain contact with consumers via chat while providing value-added services and more</p>
          <a className='see-more-btn mt-6 flex space-x-[10px] items-center cursor-pointer'>
            <div className='learn'>Learn more</div>
            <svg className='see-more-icon' width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.2911 4.6309C17.4863 4.43564 17.4863 4.11905 17.2911 3.92379L14.1091 0.74181C13.9138 0.546548 13.5972 0.546548 13.402 0.74181C13.2067 0.937072 13.2067 1.25365 13.402 1.44892L16.2304 4.27734L13.402 7.10577C13.2067 7.30103 13.2067 7.61762 13.402 7.81288C13.5972 8.00814 13.9138 8.00814 14.1091 7.81288L17.2911 4.6309ZM0.9375 4.77734H16.9375V3.77734H0.9375V4.77734Z" fill="#4D30A6" />
            </svg>
          </a>
        </div>
      </div>
      <div className="mt-20 flex lg:flex-row-reverse flex-col justify-center  mx-auto w-[94%]">
        <div className="lg:w-[55%] mx-auto lg:ml-14 flex justify-end">
          <img className='sm:w-[652px] sm:h-[330px]' src={`${baseURL}images/Chatbot.png`} alt="cardbg2" />
        </div>
        <div className='flex flex-col justify-center lg:mt-0 mt-6  lg:w-[45%]'>
          <h3 className='lg:text-left w-auto lg:w-[11/12]'>Simplify your customer support and streamline the process effortlessly with automation</h3>
          <p className='section-para lg:text-left md:mt-6 mt-3 w-auto lg:w-11/12'>An intelligent chatbot that operates across several channels efficiently automates your service activities, such as appointment booking and scheduling. Our AI chatbot is a cutting-edge interactive intelligence program that communicates with your guests in their language</p>
          <a className='see-more-btn mt-6 flex space-x-[10px] items-center cursor-pointer'>
            <div className='learn'>Learn more</div>
            <svg className='see-more-icon' width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.2911 4.6309C17.4863 4.43564 17.4863 4.11905 17.2911 3.92379L14.1091 0.74181C13.9138 0.546548 13.5972 0.546548 13.402 0.74181C13.2067 0.937072 13.2067 1.25365 13.402 1.44892L16.2304 4.27734L13.402 7.10577C13.2067 7.30103 13.2067 7.61762 13.402 7.81288C13.5972 8.00814 13.9138 8.00814 14.1091 7.81288L17.2911 4.6309ZM0.9375 4.77734H16.9375V3.77734H0.9375V4.77734Z" fill="#4D30A6" />
            </svg>
          </a>
        </div>
      </div>
      <div className="mt-20 flex lg:flex-row flex-col justify-center mx-auto w-[94%]">
        <div className="lg:w-[55%] mx-auto">
          <img className='sm:w-[540px] sm:h-[330px]' src={`${baseURL}images/smart.png`} alt="cardbg3" />
        </div>
        <div className='flex flex-col justify-center lg:mt-0 mt-6 lg:ml-14 lg:w-[45%]'>
          <h3 className='lg:text-left'>Smart Decisions Made Possible with Intelligent Analytical Data</h3>
          <p className='section-para lg:text-left md:mt-6 mt-3 w-11/12'>Obtain essential Business insights through keyword search and topic-based analytics of social media data. Monitor peers and efficiently manage various social media accounts from a single platform</p>
          <a className='see-more-btn mt-6 flex space-x-[10px] items-center cursor-pointer'>
            <div className='learn'>Learn more</div>
            <svg className='see-more-icon' width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.2911 4.6309C17.4863 4.43564 17.4863 4.11905 17.2911 3.92379L14.1091 0.74181C13.9138 0.546548 13.5972 0.546548 13.402 0.74181C13.2067 0.937072 13.2067 1.25365 13.402 1.44892L16.2304 4.27734L13.402 7.10577C13.2067 7.30103 13.2067 7.61762 13.402 7.81288C13.5972 8.00814 13.9138 8.00814 14.1091 7.81288L17.2911 4.6309ZM0.9375 4.77734H16.9375V3.77734H0.9375V4.77734Z" fill="#4D30A6" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  )
}

export default SectionC