import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useEffect } from 'react'
import { baseURL } from '../constants/BaseUrl'
function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Header />
      <div className='relative h-[671px] md:mt-[100px] mt-[80px]'>
      <div className='section-wraper'>
      <div className="contact-us-gradient absolute top-0 left-0 w-1/2 h-[600px]"></div>
      <div className='contact-us-gradient1 absolute bottom-0 right-0 w-1/2 h-[300px]'></div>

        <div className="relative contact-banner w-3/6">
          <div className="flex lg:flex-row flex-col lg:justify-center items-center pt-12 lg:pt-0 lg:text-left text-center w-full lg:w-[55%] h-full relative lg:bottom-5">
            <h2 className='font-semibold lg:w-[60%] w-10/12 leading-10 md:leading-[64px]'>Welcome back! Please log in to continue!</h2>
            <div className='hidden lg:block ml-[100px] w-[1px] h-[110px] bg-[#9B9B9B]'></div>
          </div>
          <div className="signup-form absolute lg:top-16 lg:left-[60%] md:top-[40%] top-[35%] lg:right-0 right-1/2 left-1/2 transform -translate-x-1/2 lg:-translate-x-0">
            <form action="">
              <input type="text" className=' placeholder:text-[8px] lg:placeholder:text-xs' placeholder='Email' required />
              <input type="text" className=' placeholder:text-[8px] lg:placeholder:text-xs' placeholder='Password' />
              <div className="flex w-full  justify-end">
                <div className='cursor-pointer font-normal text-xs sm:text-sm text-[#787878] mt-[-18px]'>Forget password?</div>
              </div>
              <button type='submit' className='log text-[10px] md:text-base font-semibold text-white bg-[#4D30A6] w-full rounded-md py-3 mt-8'>Log in</button>
            </form>
            <div className='text-base font-medium mt-8'>or</div>
              <button className='signIn flex justify-center items-center space-x-3 md:space-x-6 bg-white'>
              <img className='w-4 h-4' src={`${baseURL}images/Google.png`} alt="Google" />
                <div className='font-medium text-[10px] md:text-base text-[#787878]'>Sign in with Google</div>
              </button>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </div>

  )
}

export default Contact