import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { baseURL } from '../constants/BaseUrl';
 
function SectionE() {

    const [counterOn, setCounterOn] = useState(false)


    return (
        <div className='md:mt-[80px] mt-[60px] m-4  py-14 '>
            <div className='flex lg:flex-row flex-col  text-white'>
                <div className="flex relative overflow-hidden justify-end lg:w-1/2 w-full sm:min-h-[400px] min-h-[450px] items-center bg-[#4D30A6]  rounded-tl-2xl lg:rounded-bl-2xl rounded-bl-none lg:rounded-tr-none rounded-tr-2xl  lg:py-[140px] py-14">
                    <div className='stat-head3 2xl:w-[420px] lg:w-4/6 w-full xl:text-start lg:text-left lg:mr-20 lg:px-0 px-4'>Ensure business growth with superior customer interaction</div>
                    <div className="absolute md:flex hidden top-0 left-0"><img src={`${baseURL}images/gradtop-3.png`} alt="" /></div>
                </div>
                <div className="relative overflow-hidden flex lg:justify-start justify-center lg:w-1/2 w-full   rounded-br-2xl lg:rounded-tr-2xl rounded-tr-none lg:rounded-bl-none rounded-bl-2xl rounded-none bg-[#5E36D9] lg:py-[140px] py-14 lg:pb-0 pb-20">
                    <div className='flex 2xl:w-[400px] lg:w-[60%] sm:w-[70%] w-full xl:text-start sm:text-left justify-center lg:ml-20'>
                        <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)} className='stat-no3 flex-col mx-auto space-y-10'>
                            <div className="flex md:flex-row flex-col md:space-y-0 space-y-10 justify-between">
                                <div className='md:mx-0 mx-auto'><div className='font-bold' >{counterOn?<CountUp start={0} duration={2.75} end={10}/>:0}x</div><div className='stat-sub3 lg:text-left text-center'>Growth</div> </div>
                                <div className='md:mx-0 mx-auto'><div className='font-bold'>{counterOn?<CountUp start={0} duration={1.75} end={80}/>:0}%</div><div className='stat-sub3 whitespace-nowrap lg:text-left text-center'>Increase in <br /> customer service</div> </div>
                            </div>
                           <div className='flex flex-col md:items-start items-center'> <div className='lg:mt-4 font-bold'>{counterOn?<CountUp start={0} duration={1.75} end={12}/>:null}x</div><div className='stat-sub3 whitespace-nowrap lg:text-left text-center'>Boost Social <br /> Engagement</div></div>
                        </ScrollTrigger>
                    </div>
                    <div className="absolute md:flex hidden bottom-0 right-0"><img src={`${baseURL}images/grad-302.png`} alt="" /></div>
                </div>
            </div>
        </div>
    )
}

export default SectionE