import React, { useState, useEffect, useRef } from 'react'
import Header from './Header'
import Footer from './Footer'
import axios from 'axios'

function Contact() {


  const [name, SetName] = useState("")
  const [num, SetNum] = useState("")
  const [mail, SetMail] = useState("")
  const [business, SetBusiness] = useState("")
  const [position, SetPosition] = useState("")
  const [isLoad, SetLoad] = useState(false)
  const formRef = useRef(null)
  const [showMsg, setMsg] = useState(false)
  const [mailError, SetMailError] = useState(false)
  const [phoneError, SetphoneError] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');

  const handleProductChange = (e) => {
    setSelectedProduct(e.target.value);
  };
  const handleIndustryChange = (e) => {
    setSelectedIndustry(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])




  const validatePhone = (value) => {
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(value)) {
      SetphoneError('Invalid phone number');
    } else {
      SetphoneError('');
    }
  };

  const changeName = (e) => {
    SetName(e.target.value);
  }


  const changeNumber = (e) => {
    const phoneRegex = /^\d+$/;
    const pNum = e.target.value;
    SetNum(pNum);
    if (!phoneRegex.test(pNum) || pNum.length < 10) {
      SetphoneError(true);
    } else {
      SetphoneError(false);
    }
  }


  const changeMail = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const eValue = e.target.value
    SetMail(eValue);
    if (!emailRegex.test(eValue)) {
      SetMailError(true);
    } else {
      SetMailError(false);
    }
  }


  const changeBusiness = (e) => {
    SetBusiness(e.target.value);
  }

  const changePosition = (e) => {
    SetPosition(e.target.value);
  }


  const submitHandle = async (e) => {
    e.preventDefault();
    try {
      SetLoad(true)
      await axios({
        method: "post",
        url: "https://n9mi8lf6z8.execute-api.ap-south-1.amazonaws.com/v2/sales",
        data: JSON.stringify({
          "firstName": name,
          "surName": "",
          "mob": num,
          "email": mail,
          "country": "from CX",
          "hotelName": "from CX",
          "hotelClass": "from CX",
          "howSalesHelp": "from CX",
          "comments": "Product :" + selectedProduct + "," + "Industry :" + selectedIndustry,
          "subscription": "from CX"
        }),
        headers: { "Content-Type": "application/json" },
      }).then(function (response) {
        // console.log(response.status);
        if (response.status == 200) {
          formRef.current.reset();
          window.scrollTo(0, 0)
          setMsg(true);
          setTimeout(() => {
            setMsg(false);
          }, 5000);
        }
      })
      SetLoad(false)

    } catch {
      console.log("error");
    }
  }

  return (
    <div>
      <Header />
      <div className='relative lg:h-[671px] h-[800px] md:mt-[100px] mt-[80px]'>
        <div className='section-wraper'>
          <div className="contact-us-gradient absolute top-0 left-0 w-1/2 h-[600px]"></div>
          <div className='contact-us-gradient1 absolute bottom-0 right-0 w-1/2 h-[300px]'></div>

          <div className="relative contact-banner w-3/6">
            <div className="flex flex-col lg:justify-center items-center pt-12 lg:pt-0 lg:text-left text-center w-full lg:w-[55%] h-full relative lg:bottom-5">
              <h2 className='font-semibold lg:w-[60%] w-10/12'>Get A Free Demo</h2>
              <div className='contact-sub lg:text-left text-center pt-4  lg:w-[60%]'>Experience our product in action! Schedule a personalized demo with our experts to discover how our solution can transform your Industry.</div>
            </div>
            <div className="contact-form lg:w-[30%] sm:w-[80%] w-[90%] absolute lg:top-16 lg:left-[60%] sm:top-[40%] top-[45%] lg:right-0 right-1/2 left-1/2 transform -translate-x-1/2 lg:-translate-x-0">
              <form ref={formRef} onSubmit={submitHandle} action="">
                <input type="text" onChange={changeName} className='placeholder:text-[8px] lg:placeholder:text-xs' placeholder='Name' required />
                <div className='relative'><input type="text" onChange={changeNumber} className='placeholder:text-[8px] lg:placeholder:text-xs' placeholder='+91' required />
                  {phoneError ? <div className='form-error '>Invalid phone number</div> : null}</div>
                <div className='relative'> <input type="text" onChange={changeMail} className='placeholder:text-[8px] lg:placeholder:text-xs' placeholder='Email' required />
                  {mailError ? < div className='form-error'> Invalid email</div> : null}</div>
                {/* <input type="text" onChange={changeBusiness} className='placeholder:text-[8px] lg:placeholder:text-xs' placeholder='Business' />
                <input type="text" onChange={changePosition} className='placeholder:text-[8px] lg:placeholder:text-xs' placeholder='Position' /> */}
                <div className='relative'>
                  <select onChange={handleProductChange} value={selectedProduct} class="py-4 placeholder:text-[8px] lg:placeholder:text-xs" required>
                    <option selected class="">product</option>
                    <option value="chatbot">Chatbot</option>
                    <option value="cx">CX</option>
                  </select>
                </div>
                <div className='relative'>
                  <select onChange={handleIndustryChange} value={selectedIndustry} class="placeholder:text-[8px] lg:placeholder:text-xs" required>
                    <option selected class="">Industries</option>
                    <option value="hospitality">Hospitality</option>
                    <option value="healthcare">Health care</option>
                    <option value="ecommerce">E-commerce</option>
                    {/* <option value="government">Government</option> */}
                    {/* <option value="automobile">Automobile</option> */}
                    {/* <option value="education">Education</option> */}
                    <option value="others">Others</option>
                  </select>
                </div>
                <div className="flex w-full  justify-end">
                  <button className='submit-btn' type="submit">{isLoad ? "Loading..." : "Submit"}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {
          showMsg ? <div className="fixed md:top-[76px] top-[50px] whitespace-nowrap successMsg tracking-wide font-medium sm:right-[0px]  right-1/2 sm:translate-x-0 translate-x-1/2 text-green-900 rounded-sm shadow-md bg-green-300  text-sm px-8 py-4">
            Thank You for contacting us!! We will connect you soon.
          </div> : null
        }
      </div >
      <Footer />
    </div >

  )
}

export default Contact