import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Body from './Body'
import { useEffect } from 'react'
function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='landing-page'>
      <Header />
      <Body/>
      <Footer/>
    </div>
  )
}

export default Landing